import { useSocket } from '@autobid/web-socket/src/helper'
import { defineStore } from 'pinia'
import { ref } from 'vue'

type Event = Record<string, (eventData: unknown) => unknown>

export const useWebSocketStore = defineStore('useWebSocket', () => {
  const events = ref<Record<string, Event>>({})
  const socket = useSocket()
  const getAmountOfRegisteredCallbacks = (eventName: string) => {
    return Object.keys(events.value[eventName] ?? {}).length
  }
  /**
   * @param eventName - An WS server event name that you want listen to
   * @param callbackName - unique name of your action that you trigger, it make it possible to call multiple functions at one listener
   * @param callback - callback function
   */
  const startListen = (
    eventName: string,
    callbackName: string,
    callback: (eventData: unknown) => unknown
  ) => {
    const amountOfRegisteredCallbacks =
      getAmountOfRegisteredCallbacks(eventName)

    events.value[eventName] = {
      ...(events.value[eventName] ?? {}),
      [callbackName]: callback
    }

    // it's a first listened event - initialize listening
    if (!amountOfRegisteredCallbacks && socket) {
      socket.on(eventName, (eventData: { time: number; context: unknown }) => {
        for (const loopCallbackName in events.value[eventName]) {
          events.value[eventName][loopCallbackName](eventData)
        }
      })
    }
  }

  const endListen = (eventName: string, callbackName: string) => {
    delete events.value[eventName]?.[callbackName]

    const amountOfRegisteredCallbacks =
      getAmountOfRegisteredCallbacks(eventName)

    if (!amountOfRegisteredCallbacks && socket) {
      // there is no callbacks - destroy listening
      socket.off(eventName)
    }
  }

  return { events, startListen, endListen }
})
